@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixin';

.createNewBot {
    width: 74.6vw;
    margin: 2vw 7.85vw 2vw 3.85vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        padding: $tablet40px $tablet88px 0 $tablet88px;
        margin: 0;
        width: 100%;
    }

    @media only screen and (max-width: 499px) {
        padding: $mobile20px $mobile25px 0 $mobile25px;
        margin: 0;
        width: 100%;
    }

    &__titleWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin-bottom: 4vw;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: 7vw;
        }
    }

    &__title {
        font-family: $MontserratRegular;
        color: $textDarkLight;
        font-size: 0.8vw;
        line-height: 0.8vw;
        letter-spacing: 0.1vw;
        text-transform: uppercase;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            font-size: 2.14vw;
            line-height: $tablet32px;
        }

        @media only screen and (max-width: 499px) {
            font-size: 3.3vw;
            line-height: $mobile24px;
        }
    }

    &__wrapperInput {
        margin-bottom: 2vw;
        width: 15vw;
        position: relative;

        &:not(:nth-child(4n)) {
            margin-right: 2.75vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin-right: 0;
            }

            @media only screen and (max-width: 499px) {
                margin-right: 0;
            }
        }

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            width: 23vw;
        }

        @media only screen and (max-width: 499px) {
            margin-bottom: 7vw;
            width: 100%;
        }

        &_invalid {
            color: $red;
            bottom: -1.3vw;
            font-family: $MontserratRegular;
            font-weight: 400;
            font-size: 0.8vw;
            width: fit-content;
            position: absolute;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                bottom: -15px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
                bottom: -15px;
            }
        }
    }

    &__wrapperInputLabel {
        display: flex;
        align-items: center;

        label {
            margin-bottom: 0;
        }
    }

    &__wrapper {

        &_leftSide {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                flex-wrap: wrap;
                align-items: flex-end;
                margin-bottom: 0;
                justify-content: space-between;
            }

            @media only screen and (max-width: 499px) {
                flex-wrap: wrap;
            }

            &:not(:last-child) {
                margin-bottom: 3.3vw;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    margin-bottom: 0;
                }

                @media only screen and (max-width: 499px) {
                    margin-bottom: $mobile30px;
                }
            }
        }

        &_rightSide {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 2vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                margin: 4vw 0 0 0;
            }
        }

        &_title {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            margin-bottom: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
                margin-bottom: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: 3.3vw;
                line-height: $mobile10px;
                margin-bottom: $mobile10px;
            }
        }

        &_input {
            height: 3.91vw;
            line-height: 3.91vw;
            background: #f2f3f9;
            border-radius: 0.1vw;
            font-family: $MontserratRegular;
            font-size: 1.1vw;
            color: $textDarkLight;
            border-radius: 0.1vw;
            border: 1px solid #f2f3f9;
            outline: none;
            padding: 0 1vw;
            margin-top: 0.7vw;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                width: 100%;
                height: $tablet60px;
                line-height: $tablet60px;
                margin-top: $tablet10px;
                font-size: $tablet16px;
                padding: $tablet12px;
                border: 1px solid #f2f3f9;
            }

            @media only screen and (max-width: 499px) {
                width: 100%;
                max-width: 100%;
                border: 1px solid #f2f3f9;
                height: $mobile60px;
                line-height: $mobile60px;
                margin-top: $mobile10px;
                font-size: $mobile16px;
                padding: $mobile12px;
            }

            &:hover,
            &:active,
            &:focus {
                border: 1px solid $blueDark;

                @media only screen and (max-width: 1024px) {
                    border: 1px solid $blueDark;
                }
            }

            &::placeholder {
                background: #f2f3f9;
                border-radius: 0.1vw;
                font-family: $MontserratRegular;
                font-size: 1.1vw;
                color: $greyButton;

                @media only screen and (min-width: 500px) and (max-width: 1024px) {
                    font-size: $tablet16px;
                    border-radius: 2px;
                }

                @media only screen and (max-width: 499px) {
                    border-radius: 2px;
                    font-size: $mobile16px;
                }
            }
        }

        &_label {
            font-family: $MontserratRegular;
            font-size: 0.7vw;
            line-height: 0.7vw;
            color: $textDarkLight;
            display: flex;
            flex-direction: column;

            @media only screen and (min-width: 500px) and (max-width: 1024px) {
                font-size: $tablet10px;
                line-height: $tablet10px;
            }

            @media only screen and (max-width: 499px) {
                font-size: $mobile10px;
                line-height: $mobile10px;
            }
        }
    }

    &__btnSubmit {
        @include mainBtnStyle;
        @include greenBtnWithBorderAndBackground;
        font-family: $MontserratRegular;
        height: 3.15vw;
        width: fit-content;
        min-width: 15vw;

        @media only screen and (min-width: 500px) and (max-width: 1024px) {
            margin: $tablet30px 0;
            width: 31.3vw;
            height: $tablet60px;
        }

        @media only screen and (max-width: 499px) {
            width: 100%;
            height: 16vw;
            font-size: 3.2vw;
            line-height: 3.2vw;
            margin-bottom: 0;
            margin: $mobile30px 0;
        }
    }

    &__btnSubmit {
        margin-top: 1vw;
    }
}

.greenRedButtonWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2vw;

    @media only screen and (min-width: 500px) and (max-width: 1024px) {
        margin-top: $tablet20px;
    }
}
